<template>
    <div class="content">
	    <div class='film'>
            <div class="effect">
                <div class="grain"></div>
            </div>
        </div>
    </div>
    <div id="owl">
        <img src="/owl.png" alt="owl" width="200"><br>
        <canvas ref="canvasShown"></canvas>
        <p class="text">LARAVEL/VUE.JS OHJELMOINTIA ILTAVUOROSSA<br><br>ILTAVUORO@ILTAVUORO.FI</p>
    </div>
</template>
  
  <script>
  export default {
    data() {
        return {
            animationInterval: null,
        };
    },
    mounted() {
        this.initCanvas();
    },
    methods: {
      initCanvas() {
        const canvasHidden = document.createElement("canvas");
        const ctxHidden = canvasHidden.getContext("2d");
        
        const canvasShown = this.$refs.canvasShown;
        canvasShown.width = 250;
        canvasShown.height = 100;
        const ctxShown = canvasShown.getContext("2d");
        
        const init = () => {
          canvasHidden.width = 250;
          canvasHidden.height = 100;
    
          ctxHidden.clearRect(0, 0, canvasHidden.width, canvasHidden.height);
          ctxHidden.textAlign = "center";
          ctxHidden.textBaseline = "middle";
          ctxHidden.font = "50px Share Tech Mono, monospace";
          ctxHidden.fillStyle = "#daf6ff";  // Adjusted to a light orange shade

// First Shadow
ctxHidden.shadowColor = 'rgba(255, 122, 0, 1)';  // Adjusted to vibrant orange
ctxHidden.shadowBlur = 20;
ctxHidden.shadowOffsetX = 0;
ctxHidden.shadowOffsetY = 0;
ctxHidden.fillText("ILTAVUORO", canvasHidden.width / 2, canvasHidden.height / 2);

// Clear shadow before drawing again
ctxHidden.shadowColor = 'transparent';

// Second Shadow
ctxHidden.shadowColor = 'rgba(255, 122, 0, 0)';  // Adjusted to vibrant orange with 0 alpha
ctxHidden.shadowBlur = 20;
ctxHidden.shadowOffsetX = 0;
ctxHidden.shadowOffsetY = 0;
ctxHidden.fillText("ILTAVUORO", canvasHidden.width / 2, canvasHidden.height / 2);

  // Clear shadow for subsequent drawings
  ctxHidden.shadowColor = 'transparent';
    
          ctxShown.clearRect(0, 0, canvasShown.width, canvasShown.height);
          ctxShown.drawImage(canvasHidden, 0, 0);
          let i = 10; while (i--) { glitch(); }
        };
  
        const glitch = () => {
          const width = 15 + Math.random() * 15;
          const height = 5 + Math.random() * 5;
    
          const x = Math.random() * canvasHidden.width;
          const y = Math.random() * canvasHidden.height;
    
          const dx = x + (Math.random() * 40 - 20);
          const dy = y + (Math.random() * 30 - 15);
    
          ctxShown.clearRect(x, y, width, height);
          ctxShown.fillStyle = "#4a6";
          ctxShown.drawImage(canvasHidden, x, y, width, height, dx, dy, width, height);
        };
  
        setInterval(() => {
          init();
        }, 1000 / 15);
      }
    }
  };
  </script>
  