<template>
  <router-view/>
</template>

<style lang="scss">
@font-face {
  font-family: 'ShareTechMono';
  src: url('~@/assets/ShareTechMono-Regular.ttf') format('truetype');
}

html,body {
    height: 100%;
}
body {
    background: #0f3854;
    background: radial-gradient(ellipse at center, #ff7a00 0%, #451c12 50%, #000000 70%);
    background-size: 100%;
}
p {
    margin: 0;
    padding: 0;
}
#owl {
    font-family: 'Share Tech Mono', monospace;
    color: #ffffff;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #daf6ff;
    text-shadow: 0 0 20px rgba(255, 122, 0, 1), 0 0 20px rgba(255, 122, 0, 0);

    .time {
        letter-spacing: 0.05em;
        font-size: 80px;
        padding: 5px 0;
    }
    .date {
        letter-spacing: 0.1em;
        font-size: 24px;
    }
    .text {
        letter-spacing: 0.1em;
        font-size: 12px;
        padding: 20px 0 0;
    }
}

:root {
	--trsn: all 0.5s ease 0s;
	--black: #000;
}

*:before, *:after {
	position: absolute;
}



.content {
	width: 100vw;
	height: 100vh;
	position: relative;
	overflow: hidden;
    background: #0f3854;
    background: radial-gradient(ellipse at center, #ff7a00 0%, #451c12 50%, #000000 70%);
    background-size: 100%;
}


.film, .effect {
	width: 100%;
	height: 100%;
	filter: blur(0.45px) drop-shadow(0px 0px 0px #fff1);
}

.film:after, .effect:after {
	content: '';
	width: 120%;
	height: 100%;
	top: 0;
	left: 0;
	padding-left: 100px;
	opacity: 0.5;
	animation: film-scratch 0.45s steps(1) infinite;
	background: repeating-linear-gradient(90deg, #0002 0 2px, transparent 4px 37vmin);
}

.effect:after {
	left: 30%;
	animation: effect-scratch 2s infinite;
}

.grain {
	width: 100%;
	height: 100%;
}

.grain:after {
	content: '';
	width: 110%;
	height: 110%;
	top: -5%;
	left: -5%;
	opacity: .25;
	background-image: 
		repeating-conic-gradient(var(--black) 0%, transparent .00003%, transparent .0005%, transparent .00095%),
		repeating-conic-gradient(var(--black) 0%, transparent .00005%, transparent 0.00015%, transparent 0.0009%);
	animation: grain 0.5s steps(1) infinite;
	filter: drop-shadow(0px 0px 1px black);
}

@keyframes grain {
	0%, 100% { transform: translate(0, 0); }
	10% { transform: translate(-1%, -1%); }
	20% { transform: translate(1%, 1%); }
	30% { transform: translate(-2%, -2%); }
	40% { transform: translate(3%, 3%); }
	50% { transform: translate(-3%, -3%); }
	60% { transform: translate(4%, 4%); }
	70% { transform: translate(-4%, -4%); }
	80% { transform: translate(2%, 2%); }
	90% { transform: translate(-3%, -3%); }
}

@keyframes film-scratch {
	0%, 100% { transform: translateX(0); opacity: 0.5; }
	10% { transform: translateX(-1%); }
	20% { transform: translateX(1%); }
	30% { transform: translateX(-2%); opacity: 0.75; }
	40% { transform: translateX(3%); }
	50% { transform: translateX(-3%); opacity: 0.5; }
	60% { transform: translateX(8%); }
	70% { transform: translateX(-3%); }
	80% { transform: translateX(10%); opacity: 0.25; }
	90% { transform: translateX(-2%); }
}

@keyframes effect-scratch {
	0% { transform: translateX(0); opacity: 0.75; }
	10% { transform: translateX(-1%); }
	20% { transform: translateX(1%); }
	30% { transform: translateX(-2%); }
	40% { transform: translateX(3%); }
	50% { transform: translateX(-3%); opacity: 0.5; }
	60% { transform: translateX(8%); }
	70% { transform: translateX(-3%); }
	80% { transform: translateX(10%); opacity: 0.25; }
	90% { transform: translateX(20%); }
	100% { transform: translateX(30%); }
}

</style>
